let container, hase, haseDimensions;
container = document.querySelector("body");
hase = document.querySelector(".hase");

if(container && hase) {
  haseDimensions = hase.getBoundingClientRect();
  container.addEventListener("mousemove", e => animateOnMouseOver(e));
}

function animateOnMouseOver(e) {
  const cursorPositionInsideText = {
    x: e.clientX - Math.round(haseDimensions.left),
    y: e.clientY - Math.round(haseDimensions.top)
  };

  const translate = {
    x: 15 * ((cursorPositionInsideText.x / haseDimensions.width) * 2 - 1),
    y: 15 * ((cursorPositionInsideText.y / haseDimensions.height) * 2 - 1)
    // if the mouse is inside the textWrapper, this object will have values from -15 to 15
    // try 45
  };

  hase.style.transform = `translate(${-translate.x}px, ${-translate.y}px)`;
}