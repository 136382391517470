import Splide from '@splidejs/splide';

// slideNav
import SlideNav from './slideNav.es6';
var nav = new SlideNav();

// Hase, Transform
import "./hase-transform";

// Scroll-Snap
// let pageSections = document.querySelectorAll('.section');
// let sectionObserver = new IntersectionObserver (function (entries, observer) {
//   entries.forEach(function(entry) {
//     console.log('i: ' + entry.isIntersecting, entry);
//     if (entry.isIntersecting) {
//       var headerColor = entry.target.dataset.headercolor;
//       console.log('headerColor:', headerColor);
//       if(headerColor == '') { headerColor = '#fff'; }
//       document.querySelectorAll('.site-header__logo path').forEach(el => {
//         el.style.fill = headerColor;
//       });
//       document.querySelectorAll('.site-header__nav a').forEach(el => {
//         el.style.color = headerColor;
//       });
//     }
//   });
// }, { root: null, rootMargin: '0px', threshold: .1 });

// pageSections.forEach(function(pageSection) {
//   sectionObserver.observe(pageSection);
//   console.log(pageSection);
// });

// animate.css
let animateElements = document.querySelectorAll('[data-animation]');
let animateObserver = new IntersectionObserver (function (entries, observer) {
  entries.forEach(function(entry) {
    entry.target.classList.add(entry.target.dataset.animation);
  });
}, { root: null, rootMargin: '0px', threshold: 1 });
animateElements.forEach(function(animateElement) {
  animateObserver.observe(animateElement);
});

// Splide, Referenzen
document.querySelectorAll('.articles-projects').forEach(splide => {
  new Splide(splide, {  
    perPage: 1,
    arrows: false,
    pagination: true,
    autoplay: true,
    interval: 6000, 
    gap: 'var(--gutter)'
  }).mount();
});

// Galerie, Slider
document.querySelectorAll('.section-project__gallery').forEach(gallery => {
  let videoURL = gallery.dataset.video;
  if(!videoURL) return;

  let slider = gallery.querySelector('.splide__list');
  if(!slider) return;

  let li = document.createElement('li');
  li.classList.add('splide__slide', 'gallery-slider__item');
  let video = document.createElement('video');
  video.src = videoURL;
  video.width = 1280;
  video.height = 720;
  video.controls = "true"
  video.autoplay = "false"
  video.muted = "true"
  video.addEventListener('click', e => {
    if(e.currentTarget.paused == true) {
      e.currentTarget.play();
    } else {
      e.currentTarget.pause();
    }
  })

  li.append(video);
  slider.prepend(li);
});
document.querySelectorAll('.gallery-slider').forEach(splide => {
  new Splide(splide, {
    type: 'loop',
    perPage: 1,
    arrows: true,
    pagination: false,
    gap: 0
  }).mount();
});

// About, Logos
document.querySelectorAll('.gallery-brands').forEach(splide => {
  new Splide(splide, {
    perPage: 5,
    arrows: false,
    pagination: false,
    gap: '1rem',
    autoplay: true,
    interval: 4000,
    perMove: 1,
    type: 'loop',
    breakpoints: {
      768: {
        perPage: 3
      }
    }
  }).mount();
});

// Projektdetails, Prev/Next
let currentId = document.body.dataset.id; 
document.querySelectorAll('.section-project__nav').forEach(nav => {
  let navItems, prevItem, nextItem, prevLink, nextLink;
  prevLink = nav.querySelector('.nav__arrow--prev');
  nextLink = nav.querySelector('.nav__arrow--next');
  navItems = nav.querySelectorAll('[data-id]');
  navItems.forEach((navItem,i) => {
    if(navItem.dataset.id == currentId) {
      prevItem = navItems[i - 1] || navItems[navItems.length - 1];
      nextItem = navItems[i + 1] || navItems[0];
    }
  });

  if(prevLink) {
    prevLink.href = prevItem.href;
  }
  if(nextLink) {
    nextLink.href = nextItem.href;
  }
});

// Projektdetails, Video/Slider